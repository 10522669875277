import { DEFAULT_LOCATIONS, QUANTUM, DROPDOWN_AUTOCOMPLETE } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import quantumLogo from 'assets/quantum-health-care-finder.svg';

export const apiTokens = {
  dev: 'Token 101f6deecb3ab1b0bcda7a15295dd3be741e6d4f',
  staging: 'Token 83f155c4100c446c5030c6d13f0935c05b5c38fe',
  prod: 'Token ccc508e7e9bd2e1c0ae764ab7c7d8744c705c637',
};

const token = selectToken(apiTokens);

const quantumConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'quantum',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: QUANTUM,
  EMBOLD_CLIENT: QUANTUM,
  EMBOLD_CLIENT_SLUG: QUANTUM,

  // toggles
  SHOW_TELEHEALTH: false,
  SHOW_WHEELCHAIR_ACCESSIBLE: false,
  SHOW_DISCLAIMER: false,
  SHOW_LAYMANS_INTRO_MODAL: true,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  CLOSED_ACCESS: true,

  // login
  SHOW_REGION_SELECTOR: true,

  // other
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Nashville },
  LOGIN_NETWORK_NAME: 'quantumUserId',
  LOGIN_COOKIE_AUTH_REGEX: [
    'quantum|aetnachoiceposii|aetnaopenchoiceppo|aetnapremiercarenetwork|aetnaselectopenaccess|aetnasignatureadministrators|aetnalehighvalleyppo|aetnapremiercarenetworkplusutah|aetnabroadpponetwork|bluecardppobasic|bluecardtraditional|bluecardppoepo|uhcoptionsppo|uhccore|uhcselectplus|uhcchoiceplus|uhcchoicepluspos|uhcharvardppcchoiceplus|uhcharvardppcuhcptionsppo|uhcnexusacooab|mmosupermedppo|cignaopenaccessplus|cignachoicefundppo',
    'i',
  ],

  // content
  DISPLAY_NAME: 'Quantum',
  SCHEDULE_PHONE_NUMBER: '833-740-3258',
  HEADER_LOGO: quantumLogo,
  EMBOLD_LOGO_PRESENT: false,
  WELCOME_MESSAGE: `Care Finder is your guide to quickly and easily finding the doctors in your network and
  area that have proven to provide the best care.`,
  LOCAL_LOGO_ASSET: quantumLogo,
  FORM_VARIANT: DROPDOWN_AUTOCOMPLETE,
  NO_WELCOME_LINK: true,
};

export default quantumConfig;
