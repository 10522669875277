import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _analyticsActions } from 'analytics/index';
import PropTypes from 'propTypes/index';
import { Typography, makeStyles, Grid, Box } from '@material-ui/core';

import ExpandingPill from 'components/ExpandingPill';
import { select } from 'store/toolkit';
import Service from 'icons/Service';
import CostEstimator from '../Results/ResultCard/CostEstimator';

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'start',
  },
  costEstimatorWrapper: {
    textAlign: 'center',
  },
  costEstimator: {
    marginRight: 10,
    marginTop: -10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  costEstimatorDescription: {
    marginTop: -10,
    marginLeft: -10,
  },
  serviceDesc: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 11,
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  serviceName: {
    textAlign: 'center',
    textTransform: 'capitalize',
  },
}));

export default function ServiceInfoContent({ service, mobileView, fullWidthServiceDescription }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const serviceContentId = 'service-details';

  const COST_ESTIMATOR_DESCRIPTION = useSelector(select.featureFlags.costEstimatorDescription);

  const handleOpened = useCallback(() => {
    dispatch(_analyticsActions.serviceProfileView(service.serviceName));
  }, [dispatch, service]);

  if (!service) return null;

  return (
    <ExpandingPill
      label="Service"
      labelIcon={<Service color="primary" />}
      title={
        <Typography variant="h3" component="span" className={classes.serviceName}>
          {service?.serviceName}
        </Typography>
      }
      variant={mobileView ? 'mobile' : 'desktop'}
      onOpen={handleOpened}
    >
      <Grid
        className={classes.content}
        container
        justifyContent={mobileView ? 'center' : 'space-between'}
        id={serviceContentId}
      >
        <Grid item className={classes.costEstimatorWrapper} xs="auto" md={mobileView ? 6 : 4}>
          <Box className={classes.costEstimator} display="flex" justifyContent="center">
            <CostEstimator />
          </Box>
          {Boolean(COST_ESTIMATOR_DESCRIPTION) && (
            <Typography className={classes.costEstimatorDescription}>
              {COST_ESTIMATOR_DESCRIPTION}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={fullWidthServiceDescription ? 12 : 8}>
          <Box className={classes.serviceDesc}>
            <Typography variant="h4">About this service:</Typography>
            <Typography>{service.descriptionPlainLanguage}</Typography>
          </Box>
        </Grid>
      </Grid>
    </ExpandingPill>
  );
}

ServiceInfoContent.propTypes = {
  mobileView: PropTypes.bool.isRequired,
  service: PropTypes.shape({
    descriptionPlainLanguage: PropTypes.string,
    serviceName: PropTypes.string,
  }).isRequired,
  fullWidthServiceDescription: PropTypes.bool.isRequired,
};
