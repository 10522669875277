import React, { useCallback, useMemo } from 'react';
import PropTypes, { SpecialtyDataShape } from 'propTypes/index';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { actions, select, thunks } from 'store/toolkit';
import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';
import { CARE_CATEGORIES, PLACE_TYPE, PROVIDER_TYPE } from 'utils/constants';
import { useFocusTo } from 'utils/FocusRefContext';

import SearchAppendixItem from './SearchAppendixItem';
import { sortAndAddLetterAnchors } from './utils';
import { APPENDIX_STRING_MAP } from './SearchAppendix';

const useStyles = makeStyles((theme) => ({
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  listItem: {
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
  },
  subList: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
  },
  subListHeader: {
    color: theme.palette.grey[700],
    textTransform: 'uppercase',
    fontSize: '.75rem',
    marginBottom: theme.spacing(0.5),
    display: 'block',
    letterSpacing: 0.5,
  },
}));

const SEARCH_TYPE_MAP = {
  [PROVIDER_TYPE]: CARE_CATEGORIES.PROVIDER_SPECIALTY,
  [PLACE_TYPE]: CARE_CATEGORIES.FACILITY_TYPE,
};

export default function SearchAppendixItems({ resultType, specialties, filter, scrollRefs }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchDispatch = useSearchDispatchWithHistory();
  const history = useHistory();
  const focusTo = useFocusTo();
  const loading = useSelector(select.specialties.loadingSpecialties);
  const error = useSelector(select.specialties.failedToLoadSpecialties);
  const locationResolved = useSelector(select.location.locationResolved);
  const isXsDown = useSelector(select.ui.isXsDown);

  const { listLabel, subspecialtyLabel } = useMemo(
    () => APPENDIX_STRING_MAP[resultType] || {},
    [resultType]
  );
  const searchType = useMemo(() => SEARCH_TYPE_MAP[resultType], [resultType]);

  const specialtiesAndLetterAnchors = useMemo(
    () => sortAndAddLetterAnchors(specialties),
    [specialties]
  );

  const handleClick = useCallback(
    (specialty) => {
      if (!searchType) {
        console.error('Search type required, abandoning search');
        return;
      }
      dispatch(actions.search.selectSpecialtyFromAppendix({ ...specialty, searchType }));
      if (locationResolved) searchDispatch(thunks.results.executeSearch());
      else {
        // fallback for edge case when no valid location is available in store
        history.push(isXsDown ? '/search' : '/results');
        setTimeout(() => focusTo.locationInput(), 500);
        dispatch(actions.results.reset());
        dispatch(
          actions.notifications.create({
            message: 'Enter your location to submit a search',
            severity: 'info',
            duration: 6000,
          })
        );
      }
    },
    [searchType, dispatch, locationResolved, searchDispatch, history, isXsDown, focusTo]
  );

  if (error) {
    return <Typography color="error">Error loading {listLabel}</Typography>;
  }

  if (filter?.length && !specialties?.length) {
    return (
      <div>
        No results found for your filter:{' '}
        <em>
          <b>{filter}</b>
        </em>
      </div>
    );
  }

  if (loading || !specialties?.length) {
    return (
      <>
        <Skeleton width="100%" height={50} aria-label={`${listLabel} are loading`} />
        <Skeleton width="100%" height={50} aria-hidden="true" />
        <Skeleton width="100%" height={50} aria-hidden="true" />
        <Skeleton width="100%" height={50} aria-hidden="true" />
        <Skeleton width="100%" height={50} aria-hidden="true" />
      </>
    );
  }

  return (
    <ul className={classes.list}>
      {specialtiesAndLetterAnchors.map((item) => {
        if (item.anchor)
          return (
            <div
              tabIndex={-1} // programmatically focusable
              id={`anchor-${item.anchor}`}
              ref={(el) => {
                scrollRefs.current[item.anchor] = el;
              }}
              key={`anchor-${item.anchor}`}
              aria-label={`Start of items with letter ${item.anchor}`}
            />
          );
        return (
          <li className={classes.listItem} key={`specialty-${item.specialtyId}`}>
            <SearchAppendixItem
              filter={filter}
              title={item.specialtyName}
              description={item.description}
              onClick={() => handleClick(item)}
            />
            {item.subspecialties?.length > 0 && (
              <ul
                className={clsx(classes.list, classes.subList)}
                aria-labelledby={`focus-areas-${item.specialtyId}`}
              >
                <Typography
                  id={`focus-areas-${item.specialtyId}`}
                  classes={{ root: classes.subListHeader }}
                  component="span"
                >
                  {item.specialtyName} {subspecialtyLabel}:
                </Typography>
                {item.subspecialties.map((subitem) => (
                  <li className={classes.listItem} key={`subspecialty-${subitem.subspecialtyId}`}>
                    <SearchAppendixItem
                      filter={filter}
                      title={subitem.subspecialtyName}
                      description={subitem.description}
                      onClick={() => handleClick(subitem)}
                    />
                  </li>
                ))}
              </ul>
            )}
          </li>
        );
      })}
    </ul>
  );
}

SearchAppendixItems.propTypes = {
  specialties: PropTypes.arrayOf(SpecialtyDataShape).isRequired,
  resultType: PropTypes.oneOf([PLACE_TYPE, PROVIDER_TYPE]).isRequired,
  filter: PropTypes.string,
  scrollRefs: PropTypes.shape({ current: PropTypes.shape() }).isRequired,
};

SearchAppendixItems.defaultProps = {
  filter: '',
};
