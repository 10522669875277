import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, useField, useFormikContext } from 'formik';
import { Grid, Typography, MenuItem, CircularProgress, InputAdornment } from '@material-ui/core';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import { _analyticsActions } from 'analytics/index';
import Tooltip from 'components/Tooltip';
import AgreeToTermsLabel from './AgreeToTermsLabel';
import CheckboxInput from '../CheckboxInput';
import SubmitButton from '../SubmitButton';
import { CHECKBOX_ERROR_TEXT, NETWORK_SELECT_ERROR_TEXT } from './errorText';
import TextInput from '../TextInput';
import useEnsembleNetworksOptionsByZipcode from './useEnsembleNetworkOptions';

const ZIP = 'zipcode';
const NETWORK_SLUG = 'networkSlug';
const TERMS = 'termsChecked';

const initialValues = {
  [ZIP]: '',
  [NETWORK_SLUG]: '',
  [TERMS]: false,
};

function NetworkInput(props) {
  const [{ value: zipcode }, { error: zipcodeError }, { setTouched: setZipcodeTouched }] =
    useField(ZIP);
  const [{ value: networkValue }, , networkHelpers] = useField(NETWORK_SLUG);
  const { isSubmitting, isValid } = useFormikContext();

  const dispatch = useDispatch();

  useEffect(() => {
    // clear the network field on zipcode change
    networkHelpers.setValue('');
  }, [zipcode, networkHelpers]);

  const { options, isLoading } = useEnsembleNetworksOptionsByZipcode(zipcode);

  useEffect(() => {
    if (isSubmitting && isValid) {
      dispatch(
        _analyticsActions.zipcodeNetworkSelectionSubmitted({
          availableNetworks: options.map((opt) => opt.displayName).join(','),
          selectedNetwork: options.find((opt) => opt.slug === networkValue)?.displayName,
          zipcode,
        })
      );
    }
  }, [isSubmitting, options, networkValue, zipcode, isValid, dispatch]);

  const isDisabled = Boolean(zipcodeError || !zipcode || isLoading);

  const handleClick = useCallback(() => {
    if (isDisabled) {
      setZipcodeTouched(true);
    }
  }, [isDisabled, setZipcodeTouched]);

  const InputProps = useMemo(() => {
    if (!isLoading) return {};

    return {
      startAdornment: (
        <InputAdornment position="start" style={{ paddingLeft: 8 }}>
          <CircularProgress size={16} />
        </InputAdornment>
      ),
    };
  }, [isLoading]);

  return (
    <TextInput
      {...props}
      select
      disabled={isDisabled}
      onClick={handleClick}
      InputProps={InputProps}
    >
      {!options.length && <MenuItem disabled>Please enter a ZIP first</MenuItem>}
      {options.map((network) => (
        <MenuItem key={`option-${network.slug}`} value={network.slug}>
          {network.displayName}
        </MenuItem>
      ))}
    </TextInput>
  );
}
const useStyles = makeStyles((theme) => ({
  spaceAbove: {
    marginTop: theme.spacing(1),
  },
}));

const zipRegex = /^\d{5}$/; // from https://regexlib.com/REDetails.aspx?regexp_id=2
const formRegex = /^[0-9]*$/; // only digits
export default function EnsembleLogin({ classes, onSubmit }) {
  const appName = useSelector(select.content.appName);
  const allSlugs = useSelector(select.networks.availableSlugs);
  const localClasses = useStyles();

  const validationSchema = useMemo(
    () =>
      Yup.object({
        [ZIP]: Yup.string('Enter a Zip')
          .required('Zip code is required')
          .matches(zipRegex, 'Must be a 5 digit zip'),
        [NETWORK_SLUG]: Yup.string()
          .test('zip-test', 'Enter a valid zip code first', (value, context) => {
            const zip = context.parent[ZIP]; // get the value from the zipcode field
            return zipRegex.test(zip);
          })
          .test('null-test', NETWORK_SELECT_ERROR_TEXT, (value) => Boolean(value)) // this is essentially the same as .required() but it runs after the zip-test
          .oneOf(allSlugs, NETWORK_SELECT_ERROR_TEXT), // validates that this is a valid network for this config
        [TERMS]: Yup.bool().oneOf([true], CHECKBOX_ERROR_TEXT),
      }),
    [allSlugs]
  );

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      <Form noValidate className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Log into {appName}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Tooltip message="Enter your home zip code">
              <Typography variant="h5" color="textSecondary" component="h3">
                ZIP CODE
              </Typography>
            </Tooltip>
            <TextInput
              name={ZIP}
              label="Enter your zip code"
              fullWidth
              type="tel" // This is to make mobile devices open a numeric keyboard, but not affect any input validation
              inputProps={{
                maxLength: 5,
                onKeyPress: (e) => {
                  if (!formRegex.test(e.key)) {
                    e.preventDefault();
                  }
                },
              }}
              autoComplete="postal-code"
              classes={{ root: localClasses.spaceAbove }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" color="textSecondary" component="h3">
              NETWORK
            </Typography>
            <NetworkInput
              name={NETWORK_SLUG}
              label="Select a network"
              fullWidth
              classes={{ root: localClasses.spaceAbove }}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxInput name={TERMS} label={<AgreeToTermsLabel />} />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton>Continue</SubmitButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

EnsembleLogin.propTypes = {
  classes: PropTypes.shape({
    submitButton: PropTypes.string,
    root: PropTypes.string,
    termsAgreement: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
