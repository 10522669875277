import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { select, actions, thunks } from 'store/toolkit';
import { useQueryParams } from 'use-query-params';
import queryParamConfig from 'hooks/queryString/queryParamConfig';
import { validateLatLong } from 'utils/utils';

export const setInitialLocation = ({
  hasLocationHistory,
  setLocationFromHistory,
  isLoggedIn,
  locationParam,
  setLocationToNetworkDefault,
  shouldFetchFromZipcode,
  fetchFromZipcode,
}) => {
  if (locationParam) {
    // location set in useUrlSearch
    return;
  }

  if (hasLocationHistory) {
    setLocationFromHistory();
    return;
  }

  if (shouldFetchFromZipcode) {
    fetchFromZipcode();
    return;
  }

  if (isLoggedIn) {
    setLocationToNetworkDefault();
    return;
  }

  if (!isLoggedIn) {
    // location will be set with userLoggedIn thunk
  }
};

export default function useInitializeLocation() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(select.auth.isLoggedIn);
  const configIsLoading = useSelector(select.config.isLoading);
  const networkDefaultLocation = useSelector(select.networks.defaultLocation);

  // location from deep URL
  const [{ location: locationParam }] = useQueryParams(queryParamConfig);

  // zipcode from SAML login
  const zipcodeParam = localStorage.getItem('zipcode');
  if (zipcodeParam) localStorage.removeItem('zipcode');
  const networkSlug = useSelector(select.networks.currentSlug);
  const shouldFetchFromZipcode = zipcodeParam && networkSlug;

  // location from history
  const locationHistory = JSON.parse(localStorage.getItem('locationHistory'));
  const latestLocationSearched = locationHistory?.[0];
  const hasLocationHistory = validateLatLong(
    latestLocationSearched?.latLong?.latitude,
    latestLocationSearched?.latLong?.longitude
  );

  const fetchFromZipcode = useCallback(
    () =>
      dispatch(
        thunks.location.fetchDefaultLocationFromZipcode({ zipcode: zipcodeParam, networkSlug })
      ),
    [dispatch, networkSlug, zipcodeParam]
  );

  const setLocationToNetworkDefault = useCallback(() => {
    dispatch(actions.location.setDefaultLocation({ defaultLocation: networkDefaultLocation }));
  }, [dispatch, networkDefaultLocation]);

  const setLocationFromHistory = useCallback(() => {
    dispatch(actions.location.updateLocationFromHistory(latestLocationSearched));
  }, [dispatch, latestLocationSearched]);

  /**
   * On config load, a location will be set if
   * 1) they visited a deep-link with a valid location parameter (`locationParam`)
   * 2) they have a valid location in their local storage (`hasLocationHistory`)
   * 3) their SAML handshake provided a valid network and zipcode (`shouldFetchFromZipcode`)
   * 4) they have a valid networkSlug in their local storage (`isLoggedIn`)
   *
   * Else, they will be assigned a default location when they select their network and log in
   */
  useEffect(() => {
    if (!configIsLoading) {
      setInitialLocation({
        hasLocationHistory,
        setLocationFromHistory,
        isLoggedIn,
        locationParam,
        setLocationToNetworkDefault,
        shouldFetchFromZipcode,
        fetchFromZipcode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configIsLoading]);
}
