import React, { useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Typography, IconButton, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { CSSTransition } from 'react-transition-group';

import { select, actions } from 'store/toolkit';
import { useFocusTo } from 'utils/FocusRefContext';
import { getCookie, setCookie } from 'utils/utils';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.cookiebar-transition-enter': {
      marginBottom: '-100%',
    },
    '.cookiebar-transition-enter-active, .cookiebar-transition-enter-done': {
      transition: 'margin-bottom linear 800ms',
      marginBottom: 0,
    },
    '.cookiebar-transition-exit': {
      marginBottom: 0,
    },
    '.cookiebar-transition-exit-active': {
      marginBottom: '-100%',
      transition: 'margin-bottom linear 800ms',
    },
  },
  cookieBar: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    padding: '12px 16px',
    background: 'rgba(0,0,0,0.8)',
    maxHeight: '55vh',
    overflow: 'scroll',
    zIndex: theme.zIndex.tooltip,
    [theme.breakpoints.up('md')]: {
      padding: '12px 40px',
    },
  },
  cookieBarText: {
    color: theme.palette.common.white,
    lineHeight: 1.4,
    paddingRight: 25,
    '& a': {
      color: theme.palette.common.white,
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
      display: 'inline-block',
      paddingRight: 0,
    },
  },
  buttonWrap: {
    textAlign: 'center',
    marginTop: '10px',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
      float: 'right',
      width: '40%',
      marginTop: 0,
    },
  },
  button: {
    margin: '0 10px 10px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  iconButton: {
    color: 'white',
    position: 'absolute',
    top: 65,
    right: 0,
    '&.Mui-focusVisible': {
      outlineColor: 'white',
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      top: 0,
    },
  },
}));

function CookieAgreement() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const focusTo = useFocusTo();
  const isLoggedIn = useSelector(select.auth.isLoggedIn);
  const showCookieBanner = useSelector(select.ui.showCookieBanner);

  useEffect(() => {
    if (getCookie('cookiesAccepted') === 'true') {
      return;
    }
    dispatch(actions.ui.showCookieBanner());
  }, [dispatch]);

  const rejectCookies = useCallback(() => {
    dispatch(actions.ui.hideCookieBanner());
  }, [dispatch]);

  const acceptCookies = useCallback(() => {
    // set login and intro cookies
    setCookie('cookiesAccepted', 'true', 3, true);
    // hide banner
    dispatch(actions.ui.hideCookieBanner());
  }, [dispatch]);

  const slideIn = showCookieBanner && isLoggedIn;

  return (
    <CSSTransition
      in={slideIn}
      timeout={800}
      classNames="cookiebar-transition"
      unmountOnExit
      onExiting={focusTo.logo}
    >
      <aside className={`${classes.cookieBar} cookiebar`}>
        <Typography className={`${classes.cookieBarText} cookiebar-text`}>
          This website stores cookies on your computer to improve your experience on the site. To
          find out more about the cookies we use, see our{' '}
          <Link to="/privacy" component={RouterLink}>
            Privacy Statement
          </Link>
          .
        </Typography>
        <div className={`${classes.buttonWrap} cookiebar-button-wrap`}>
          <Button
            onClick={acceptCookies}
            variant="contained"
            color="secondary"
            // the cookie-accept-button class is used for overrides in quantumThemeVariant
            classes={{
              root: `cookie-accept-button ${classes.button}`,
            }}
          >
            Accept
          </Button>
          <Button
            onClick={rejectCookies}
            variant="outlined"
            color="secondary"
            classes={{
              root: classes.button,
              outlined: 'white-background',
            }}
          >
            Decline
          </Button>
          <IconButton
            aria-label="close"
            onClick={rejectCookies}
            className={`${classes.iconButton} cookiebar-icon-button-close`}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </aside>
    </CSSTransition>
  );
}

export default CookieAgreement;
