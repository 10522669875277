import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import useUpdateTitle from 'hooks/useUpdateTitle';
import { select } from 'store/toolkit';
import { SUPPORT_ROUTE } from '../../../navigation/navigationConstants';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';
import SupportPageSection from './SupportPageSection';
import SupportTicketSection from './SupportTicketSection';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1200,
    margin: '0px auto',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    '& h1': {
      margin: '1rem',
    },
    [theme.breakpoints.up('sm')]: { padding: `${theme.spacing(2)}px ${theme.spacing(4)}px` },
    [theme.breakpoints.up('md')]: { padding: `${theme.spacing(2)}px ${theme.spacing(5)}px` },
    [theme.breakpoints.up('lg')]: { padding: `${theme.spacing(2)}px ${theme.spacing(6)}px` },
  },
  innerContainer: {
    width: '100%',
    backgroundColor: theme.palette.grey[100],
    borderRadius: '30px',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: { padding: theme.spacing(3) },
    [theme.breakpoints.up('md')]: { padding: theme.spacing(4) },
    [theme.breakpoints.up('lg')]: { padding: theme.spacing(5) },
  },
  supportText: {
    // these are the benefits & provider guide sections above the grey container
    marginBottom: theme.spacing(4),
    '& h2': { marginTop: 0 },
    '& p': {
      fontSize: '1rem',
      [theme.breakpoints.up('sm')]: { fontSize: '1.1rem' },
    },
  },
}));

export default function SupportPage() {
  const classes = useStyles();
  const supportName = useSelector(select.content.supportName);
  const supportPhone = useSelector(select.content.supportPhoneNumber);
  const appName = useSelector(select.content.appName);
  const showPerformancePdfLink = useSelector(select.featureFlags.showPerformanceQualityMeasuresPdf);

  useUpdateTitle('Support');

  return (
    <div className={classes.root}>
      <Typography variant="h1" color="primary">
        Welcome to {appName} Support
      </Typography>

      {
        // Benefits and claims should be directed to supportName and supportPhone if available
        Boolean(supportName && supportPhone) && (
          <SupportPageSection
            title="Benefits and Claims"
            className={classes.supportText}
            id="benefits"
          >
            <Typography>
              For questions regarding benefit coverage or claims, please contact {supportName} at{' '}
              <Link href={`tel:${supportPhone}`}>{supportPhone}</Link>.
            </Typography>
          </SupportPageSection>
        )
      }

      <SupportPageSection title={appName} className={classes.supportText} id="provider-guide">
        <Typography>
          If you have questions about Embold Health or {appName}, you can{' '}
          <Link to={`${SUPPORT_ROUTE}#ticket`} component={RouterLink}>
            submit a ticket
          </Link>
          .
        </Typography>

        {showPerformancePdfLink && (
          <Typography>
            To understand how Embold Health evaluates providers please see Embold&apos;s{' '}
            <Link
              href="https://content.emboldhealth.com/external/Quality_Measures.pdf"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Open a PDF to see how Embold Health evaluates providers"
            >
              Performance Quality Measures
            </Link>
            .
          </Typography>
        )}

        <Typography>
          For additional information about Embold&apos;s provider search tool, {appName}, visit the{' '}
          <Link component={RouterLink} to="/#about">
            about
          </Link>{' '}
          section within {appName}.
        </Typography>
      </SupportPageSection>

      <div className={classes.innerContainer}>
        <FrequentlyAskedQuestions />
        <SupportTicketSection />
      </div>
    </div>
  );
}
