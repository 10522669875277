import { CONVIVA, DEFAULT_LOCATIONS, PASSWORD } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import emboldLogo from 'assets/embold-icon.png';
import emboldLoginLogo from 'assets/embold-login-logo.svg';

export const apiTokens = {
  dev: 'Token 7bd0bf5411f2605cfc42be6751c79706b1c8f85f',
  staging: 'Token fb464d1849ea4b0b9dadf84e7b09b9decfb882cc',
  prod: 'Token ',
};

const token = selectToken(apiTokens);

const convivaConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: CONVIVA,

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: CONVIVA,
  EMBOLD_CLIENT: CONVIVA,
  EMBOLD_CLIENT_SLUG: CONVIVA,

  // toggles
  SHOW_COST: false,
  SHOW_DISCLAIMER: false,
  SHOW_DR_SCORE: true,

  // login
  LOGIN_NETWORK_NAME: 'convivaUserId',
  SHOW_REGION_SELECTOR: true,
  LOGIN_COOKIE_AUTH_REGEX: [
    'conviva|aetnamedicareplanhmo|aetnamedicareplanppo|floridawmrhhn|healthspring|bluemedicareclassichmo|bluemedicarevalueppo|humanamedicareppo|humanacareplus|preferredmedicareadvantageflhmo|aarpmedicareadvantagefromuhcep',
    'i',
  ],

  // other
  HIDE_CLIENT_PROMOTE_HEADER: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Orlando },

  // content
  DISPLAY_NAME: 'Conviva',
  SCHEDULE_PHONE_NUMBER: '',
  HEADER_LOGO: emboldLogo,
  SUPPORT_LINK: 'https://emboldhealth.com/',
  FORM_VARIANT: PASSWORD,
  EMBOLD_LOGO_PRESENT: false,
  WELCOME_MESSAGE:
    'Conviva and Embold Health have partnered to bring you Embold‘s Referral Guide, your guide to quickly and easily finding the doctors in your network and area that have proven to provide the best care.',
  LOCAL_LOGO_ASSET: emboldLoginLogo,
  NO_WELCOME_LINK: false,
};

export default convivaConfig;
