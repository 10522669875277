import React from 'react';
import clsx from 'clsx';
import PropTypes from 'propTypes';
import { select } from 'store/toolkit';
import { useSelector } from 'react-redux';

import { Grid, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { MAP_ANIMATION_TRANSITION } from 'components/Results/ResultsPage';
// REMOVE
import {
  DESKTOP_EXPANDED_VIEW,
  MOBILE_CONDENSED_VIEW,
  MOBILE_EXPANDED_VIEW,
  PROVIDER_CARD_VIEW_OPTIONS,
  DESKTOP_SMALL_VIEW,
  DESKTOP_CONDENSED_VIEW,
} from 'store/slices/ui/uiConstants';
import PlaceContactCard from './PlaceContactCard';
import PlaceDirectionsButton from './PlaceDirectionsButton';
import RoundedWrapper from '../StyledWrappers/RoundedWrapper';
import ResultTitle from '../StyledWrappers/ResultTitle';
import CompareCheckbox from '../CompareCheckbox';
import PlaceDetailsButton from './PlaceDetailsButton';
import PlaceCallButton from './PlaceCallButton';
import HospitalSafetyGradeMetric from './HospitalSafetyGradeMetric';
import PlaceAttributeList from './PlaceAttributeList';
import PlacePillList from './PlacePillList';

const useStyles = makeStyles((theme) => ({
  // BASE STYLES
  cardHeader: {
    paddingBottom: 10,
  },
  headerGroup: {
    display: 'flex',
    wrap: 'nowrap',
    columnGap: 8,
  },
  contactCardWrapper: {
    width: '100%',
    minWidth: 500,
  },
  firstColumn: {
    flex: 1,
  },
  secondColumn: {
    flex: 1.25,
    marginLeft: 15,
    transition: MAP_ANIMATION_TRANSITION,
  },
  thirdColumn: {
    transition: MAP_ANIMATION_TRANSITION,
    overflow: 'hidden',
  },
  // DESKTOP_EXPANDED
  secondColumnExpanded: {
    marginRight: 15,
  },
  thirdColumnExpanded: {
    flex: 1.8,
  },
  // DESKTOP_CONDENSED
  thirdColumnCondensed: {
    flex: 0,
    width: 0,
    transform: 'translateX(15px)',
  },
  // MOBILE VIEWS
  mobileLabelText: {
    fontSize: '.75rem',
  },
  mobileCheckbox: {
    marginTop: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    '& svg': {
      fontSize: '1rem',
    },
  },
  mobileDirectionsPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
  },
  mobileHeaderButton: {
    paddingRight: 0,
  },
  // MOBILE_EXPANDED
  name: {
    columnGap: 8,
    wordWrap: 'break-word',
  },
  isHovered: {
    borderColor: theme.palette.text.secondary,
  },
  mobileWrapperColumn: {
    rowGap: 12,
  },
  mobileDetailsLeft: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12,
  },
  pillList: {
    marginBottom: theme.spacing(0.5),
  },
}));

function PlaceResultCard({ placeId, view }) {
  const isMobileView = view === MOBILE_CONDENSED_VIEW || view === MOBILE_EXPANDED_VIEW;

  if (isMobileView) return <PlaceResultCardMobile placeId={placeId} view={view} />;
  return <PlaceResultCardDesktop placeId={placeId} view={view} />;
}

export default PlaceResultCard;

const SharePlaceCardProps = {
  placeId: PropTypes.string.isRequired,
  view: PropTypes.oneOf(PROVIDER_CARD_VIEW_OPTIONS).isRequired,
};

PlaceResultCard.propTypes = SharePlaceCardProps;

function PlaceResultCardDesktop({ view, placeId }) {
  const classes = useStyles();

  const hoveredIdEqualsIdProp = useSelector(select.results.hoveredIdEqualsIdProp(placeId));
  const { name, hospitalGrade, hospitalGradeLastUpdated } = useSelector(select.place(placeId).data);

  return (
    <RoundedWrapper additionalStyles={hoveredIdEqualsIdProp ? classes.isHovered : null}>
      {/*  card header */}
      <Grid
        container
        justifyContent="space-between"
        wrap="nowrap"
        alignItems="flex-start"
        className={classes.cardHeader}
      >
        <Grid item className={classes.name}>
          <PlacePillList placeId={placeId} className={classes.pillList} />
          <ResultTitle resultId={placeId}>{name}</ResultTitle>
        </Grid>
        <Grid className={classes.headerGroup}>
          <CompareCheckbox resultId={placeId} />
          <PlaceDetailsButton placeId={placeId} accessibleLabel={name} />
        </Grid>
      </Grid>

      {/*  card content grid */}
      <Grid container>
        {/*  left grid */}
        <Grid className={classes.firstColumn}>
          <PlaceAttributeList placeId={placeId} />
          <PlaceDirectionsButton placeId={placeId} />
        </Grid>
        {/*  center grid */}
        <Grid
          item
          className={clsx(classes.secondColumn, {
            [classes.secondColumnExpanded]: view === DESKTOP_EXPANDED_VIEW,
          })}
        >
          <HospitalSafetyGradeMetric grade={hospitalGrade} lastUpdated={hospitalGradeLastUpdated} />
        </Grid>
        {/*  right grid */}
        {view !== DESKTOP_SMALL_VIEW && (
          <Grid
            item
            className={clsx(classes.thirdColumn, {
              [classes.thirdColumnCondensed]: view === DESKTOP_CONDENSED_VIEW,
              [classes.thirdColumnExpanded]: view === DESKTOP_EXPANDED_VIEW,
            })}
          >
            <Collapse in={view === DESKTOP_EXPANDED_VIEW} timeout={700}>
              <div
                className={classes.contactCardWrapper}
                aria-hidden={view !== DESKTOP_EXPANDED_VIEW}
              >
                <PlaceContactCard placeId={placeId} />
              </div>
            </Collapse>
          </Grid>
        )}
      </Grid>
    </RoundedWrapper>
  );
}

PlaceResultCardDesktop.propTypes = SharePlaceCardProps;

function PlaceResultCardMobile({ view, placeId }) {
  return (
    <RoundedWrapper>
      <PlaceResultCardMobileContent view={view} placeId={placeId} />
    </RoundedWrapper>
  );
}

export function PlaceResultCardMobileContent({ view, placeId }) {
  const { name, hospitalGrade, hospitalGradeLastUpdated } = useSelector(select.place(placeId).data);
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.mobileWrapperColumn}>
      {/* row 1 */}
      <Grid container justifyContent="space-between">
        {/*  grid left (details) */}
        <Grid item xs={8} className={classes.mobileDetailsLeft}>
          <Grid item className={classes.name}>
            <PlacePillList placeId={placeId} className={classes.pillList} />
            <ResultTitle resultId={placeId}>{name}</ResultTitle>
          </Grid>
          <PlaceAttributeList placeId={placeId} />
        </Grid>
        {/*  grid right (buttons) */}
        <Grid
          item
          xs={4}
          container
          direction="column"
          justifyContent="space-between"
          className={classes.mobileWrapperColumn}
        >
          <Grid container direction="column" alignItems="flex-end">
            <PlaceDetailsButton
              placeId={placeId}
              buttonClasses={`${classes.mobileLabelText} ${classes.mobileHeaderButton}`}
            />
            {view !== MOBILE_CONDENSED_VIEW && (
              <CompareCheckbox
                resultId={placeId}
                labelClasses={classes.mobileLabelText}
                checkboxRootClasses={classes.mobileCheckbox}
              />
            )}
          </Grid>
          <Grid container direction="column" alignItems="flex-end">
            <PlaceCallButton placeId={placeId} />
            <PlaceDirectionsButton
              placeId={placeId}
              additionalClasses={classes.mobileDirectionsPadding}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* row 2 */}
      {view === MOBILE_EXPANDED_VIEW && (
        <HospitalSafetyGradeMetric grade={hospitalGrade} lastUpdated={hospitalGradeLastUpdated} />
      )}
    </Grid>
  );
}

PlaceResultCardMobile.propTypes = PlaceResultCard.propTypes;
PlaceResultCardMobile.defaultProps = PlaceResultCard.defaultProps;

PlaceResultCardMobileContent.propTypes = PlaceResultCard.propTypes;
PlaceResultCardMobileContent.defaultProps = PlaceResultCard.defaultProps;
